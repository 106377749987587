<template>
    <footer class="relative py-1">
      <div class="container mx-auto">
        <div
          class="flex flex-wrap items-center md:justify-between justify-center"
        >
          <div class="w-full md:w-4/12 px-4 mx-auto text-center">
            <div class="textColor text-xs font-medium py-1">
              Copyright © {{ date }} & Powered by
              <a
                href="https://apollodart.com"
                class="active"
                target="_blank"
              >
                ApolloDart
              </a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  </template>
  <script>
  export default {
    data() {
      return {
        date: new Date().getFullYear(),
      };
    },
  };
  </script>
  <style scoped>
  .background{
    background-color:var(--central_bg);
  }
  .textColor{
    color: var(--textColor);
  }
  .active{
    color: var(--activeTextColor);
  }
  </style>